import { Ref, toValue } from 'vue';

import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { useFetchClient, useHandlingError, useState } from '#imports';

export interface ICampaign {
  id: string;
  title: string;
  tag: 'engagement_diagnosis' | 'culture_diagnosis';
  type: 'diagnosis';
  subtype: 'culture' | 'engagement';
  formsCount: number;
  employeeLimit: number;
  welcomeText: string;
  mailText: string;
  reminderFrequency: number;
  startsAt: string;
  finishesAt: string;
  disclosureAt: string;
  revision: number;
  createdAt: string;
  updatedAt: string;
}

export interface IFilter {
  key: string;
  value: Array<string>;
}

export interface IFilterActive {
  id: string;
  filter: string;
  group: string;
  value: string;
}

export interface IDiagnosisState {
  campaigns: Array<ICampaign>;
  filters: Array<IFilter>;
  selectedFilters: Array<string>;
  activeFilters: Array<IFilterActive>;
  queryFilters: string | null;
  queryPage: string | null;
}

export const useDiagnosisStore = defineStore('diagnosisStore', {
  state: (): IDiagnosisState => ({
    campaigns: [],
    filters: [],
    selectedFilters: [],
    activeFilters: [],
    queryFilters: null,
    queryPage: null,
  }),
  getters: {
    currentDiagnosis: (state): { name: string; params: { campaign: string } } | null => {
      const diagnosis = state.campaigns.filter((item: ICampaign) => item.type === 'diagnosis')[0] || null;

      if (!diagnosis) return null;

      const names = {
        engagement: 'campaign-diagnosis-engagement',
        culture: 'campaign-diagnosis-culture',
      };

      return { name: names[diagnosis.subtype], params: { campaign: diagnosis.id } };
    },
    getCampaignById: (state) => {
      return (id: string) => state.campaigns?.find((item) => item.id === id);
    },
    queryURl: (state) => {
      return (url: string): string => {
        const query = new URLSearchParams();

        for (const value of state.activeFilters) {
          query.append(`${value.group}[]`, value.value);
        }

        const queryFilters = query.toString() || null;

        return (
          url +
          (queryFilters ? '?' + queryFilters : '') +
          (state.queryPage ? (!queryFilters ? '?' : '&') + state.queryPage : '')
        );
      };
    },
  },
  actions: {
    /**
     * Everything you need to start the campaign
     */
    async startCampaign(id: string) {
      const { data } = await useFetchClient(() => `/api/${id}/filters`, { key: `filter-${id}` });

      this.filters = data.value;
    },
    /**
     * Data required to start the page
     */
    async startPage(url: string | Ref<string> | (() => string)) {
      this.queryPage = null;
      const resume = useState('resume', () => {});

      const { data, pending } = await useFetchClient(() => this.queryURl(toValue(url)), {
        onResponse({ response }) {
          resume.value = response._data?.resume;
        },
      });

      return { data, pending };
    },
    setFilters() {
      if (this.selectedFilters.length === 0) return;

      const arrayFilters = [];

      for (const value of this.selectedFilters) {
        const filter = JSON.parse(value);

        arrayFilters.push({ id: uuidv4(), filter, group: filter[0], value: filter[1] });
      }

      this.activeFilters = arrayFilters;
    },
    removeFilter(filter: IFilterActive) {
      const indexActive = this.activeFilters.findIndex((o: IFilterActive) => o.id === filter.id);
      const indexSelect = this.selectedFilters.findIndex((o: string) => o === filter.filter);

      this.activeFilters.splice(indexActive, 1);
      this.selectedFilters.splice(indexSelect, 1);
    },
    setFilterPage(value: any, typeKey: string = 'group') {
      const query = new URLSearchParams(this.queryPage || '');
      query.set(typeKey, value);

      this.queryPage = query.toString();
    },
    cleanFilters() {
      this.selectedFilters = [];
      this.activeFilters = [];
      this.queryFilters = null;
      this.queryPage = null;
    },
  },
});
